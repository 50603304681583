export const info = [
  {
    id: 1,
    titulo: "Asesorías",
    descripcion: `Brindamos información teórica y práctica sobre las estrategias necesarias para la inclusión educativa exitosa del niño o niña, en un aula regular.`,
  },
  {
    id: 2,
    titulo: "Educación especial",
    descripcion: `El objetivo del programa es ayudar al personal terapéutico a instaurar en el niño o niña con un rango de edad de 2 a 5 años los dispositivos de aprendizaje que le permitan desenvolverse de forma temprana en su vida diaria.`,
  },
  {
    id: 3,
    titulo: "Proyectos",
    descripcion: `El objetivo es brindar al terapeuta las herramientas necesarias para que los niños y niñas con autismo puedan desarrollar habilidades comunicativas, sociales, cognitivas, de autonomía y motoras que le permitan incluirse y desenvolverse en los diferentes contextos de su vida diaria.`,
  },
  {
    id: 4,
    titulo: "Seminarios",
    descripcion: `Los seminarios son utilizados para supervisar los ajustes necesarios a los diferentes programas de cada centro, generando las estrategias de intervención y orientación que apoyen al terapeuta y padres de los niños en su inclusión. `,
  },
];

export default info;
