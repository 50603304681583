import React, { Fragment, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import { info } from "../utils/info";

import somos from "../images/imgTerapia_QS.jpg";
import "../css/style.css";

// markup
const IndexPage = () => {
  const [titulo, settitulo] = useState("");
  const [descripcion, setdescripcion] = useState("");

  const CallModal = (id) => {
    const data = info.find((item) => item.id == id);

    settitulo(data.titulo);
    setdescripcion(data.descripcion);
  };

  return (
    <Fragment>
      <div className="container">
        <Seo title="World Autism" />
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="#inicio">
                  <StaticImage
                    src="../images/logo_WAC.jpg"
                    alt="World Autism Center"
                    className="logoMenu img-fluid"
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="#inicio"
                      >
                        Inicio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#somos">
                        Quiénes somos
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#servicios">
                        Servicios
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="#contactenos">
                        Contáctenos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div id="inicio" className="col-md-12 ps-0 pe-0">
            <div className="bannerPrincipal">
              <StaticImage
                src="../images/bannerPrincipal.jpg"
                alt="Atención Integral del Autismo"
                className="img-fluid"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
        <div id="somos" className="row bloqueQS">
          <div className="col-md-12">
            <div className="row mtQS">
              <div className="col-md-6">
                <div className="imgTerapia">
                  {/* <StaticImage
                  src="../images/imgTerapia_QS.jpg"
                  alt="Terapia"
                  className="img-fluid"
                  placeholder="blurred"
                  layout="fullWidth"
                /> */}
                  <img src={somos} alt="Terapia" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="textQS">
                  <h1>Quiénes somos</h1>
                  <div className="bordeTit">&nbsp;</div>
                  <p>
                    Somos un centro de apoyo que nació para brindar
                    acompañamiento por medio de sus diferentes servicios de
                    información y capacitación a los centros de atención a niños
                    con autismo o deficiencias del neurodesarrollo.
                  </p>
                  <p>
                    Nuestro objetivo es ayudar a estos centros para que las
                    familias de niños con capacidades diferentes logren una
                    integración social efectiva a través de programas de
                    intervención personalizados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="servicios" className="row bloqueServicios">
          <div className="col-md-12 mServ">
            <h1>Servicios</h1>
            <div className="bordeTit">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-3 text-center">
              <div
                className="cajaImagen yellow"
                data-bs-toggle="modal"
                data-bs-target="#mdServicios"
                onClick={() => CallModal(1)}
              >
                <StaticImage
                  src="../images/asesorias.jpg"
                  alt=""
                  className="bloqueImagen img-fluid"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <div className="tituloServicio yellow">
                <p>Asesorias</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 text-center">
              <div
                className="cajaImagen red"
                data-bs-toggle="modal"
                data-bs-target="#mdServicios"
                onClick={() => CallModal(2)}
              >
                <StaticImage
                  src="../images/eduEspecial.jpg"
                  alt=""
                  className="bloqueImagen img-fluid"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <div className="tituloServicio red">
                <p>Educación Especial</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 text-center">
              <div
                className="cajaImagen skyblue"
                data-bs-toggle="modal"
                data-bs-target="#mdServicios"
                onClick={() => CallModal(3)}
              >
                <StaticImage
                  src="../images/proyectos.jpg"
                  alt=""
                  className="bloqueImagen img-fluid"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <div className="tituloServicio skyblue">
                <p>Proyectos</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 text-center">
              <div
                className="cajaImagen blue"
                data-bs-toggle="modal"
                data-bs-target="#mdServicios"
                onClick={() => CallModal(4)}
              >
                <StaticImage
                  src="../images/seminarios.jpg"
                  alt=""
                  className="bloqueImagen img-fluid"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <div className="tituloServicio blue">
                <p>Seminarios</p>
              </div>
            </div>
          </div>
        </div>
        <div id="contactenos" className="row bloqueContacto">
          <div className="col-sm-12 col-md-5">
            <div className="contacto">
              <form>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nombre"
                  aria-label="default input example"
                />
                <br></br>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Correo"
                  aria-label="default input example"
                />
                <br></br>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Asunto"
                  aria-label="default input example"
                />
                <br></br>
                <div className="btnEnviar">
                  <button type="button" className="btn btn-primary">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="mdServicios" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">{titulo}</h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>{descripcion}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IndexPage;
